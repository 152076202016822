import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import NumberFormat from 'react-number-format';

import config from '../config';
import Stocks from '../stocks';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

function GForm({ data, lang }: any) {
  const classes = useStyles();
  const [requestObj, setRequestObj]: any = useState({
    pricetoearning: '',
    pricetobook: config.price_to_book,
    eps: '',
    bvps: '',
    fair_value: 0,
    market_price: '',
    stock_name: '',
    diff_price: 0,
  });

  useEffect(() => {
    if (data) {
      setRequestObj((preState: any) => ({
        ...preState,
        pricetobook: data.price_to_book,
      }));
    }
  }, [data]);

  const _calculate = () => {
    const {
      pricetoearning,
      pricetobook,
      eps,
      bvps,
      market_price,
    }: any = requestObj;

    const fair_value = Math.sqrt(pricetoearning * pricetobook * eps * bvps);

    const diff_price = market_price - fair_value;

    setRequestObj((preState: any) => ({
      ...preState,
      fair_value,
      diff_price,
    }));
  };

  const handleChange = (value: any, type: string) => {
    requestObj[type] = parseFloat(value);
    setRequestObj(requestObj);
    _calculate();
  };

  const _onAfterSelect = (data: any) => {
    let stockName = '';
    let eps = '';
    let pe = '';
    let book_value = '';
    let market_price = '';
    if (data && data != null) {
      stockName = data.name;
      eps = data.eps;
      pe = data.pe;
      book_value = data.book_value;
      market_price = data.ltp;
    }

    setRequestObj((preState: any) => ({
      ...preState,
      stock_name: stockName,
      eps,
      pricetoearning: pe,
      bvps: book_value,
    }));
  };

  const {
    fair_value,
    stock_name,
    diff_price,
    market_price,
    eps,
    bvps,
    pricetoearning,
  } = requestObj;

  const showDiv =
    stock_name != '' &&
    fair_value > 0 &&
    market_price > 0 &&
    fair_value.toFixed(2) != market_price;

  return (
    <div className={classes.root}>
      {showDiv && (
        <Paper style={{ padding: '10px' }}>
          {lang == 'en' ? (
            <div>
              The fair value of the stock {companyName(stock_name)} is{' '}
              {getValue(fair_value)}
            </div>
          ) : (
            <div>
              {companyName(stock_name)} को फेअर भ्यालु {getValue(fair_value)} हो
            </div>
          )}
        </Paper>
      )}

      <div>
        <Stocks data={data} lang={lang} onAfterSelect={_onAfterSelect} />
      </div>

      <div>
        <TextField
          label={__('MARKET_PRICE', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'market_price')}
          autoComplete="off"
          style={{ width: '300px' }}
        />
        {showDiv && (
          <span>
            {lang == 'en' ? (
              <span style={{ fontSize: '11px' }}>
                {getDiffValue(diff_price)} {diff_price > 0 ? 'high' : 'low'}{' '}
                from fair value
              </span>
            ) : (
              <span style={{ fontSize: '11px' }}>
                फेअर भ्यालु भन्दा {getDiffValue(diff_price)}{' '}
                {diff_price > 0 ? 'बढी' : 'कम'}
              </span>
            )}
          </span>
        )}
      </div>

      <div>
        <TextField
          label={__('PRICE_TO_EARNING_RATIO', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'pricetoearning')}
          autoComplete="off"
          style={{ width: '300px' }}
          value={pricetoearning}
        />
      </div>

      <div>
        <TextField
          label={__('EPS', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'eps')}
          autoComplete="off"
          style={{ width: '300px' }}
          value={eps}
        />
      </div>
      <div>
        <TextField
          label={__('BPVS', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'bvps')}
          autoComplete="off"
          style={{ width: '300px' }}
          value={bvps}
        />
      </div>
    </div>
  );
}

export default GForm;

const companyName = (name: any) => {
  return (
    <Typography color="primary" component="span" style={{ fontSize: '16px' }}>
      <strong>{name}</strong>
    </Typography>
  );
};

const getValue = (value: any) => {
  return (
    <Typography component="span" color="primary" style={{ fontSize: '22px' }}>
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getDiffValue = (value: any) => {
  return (
    <Typography
      component="span"
      style={{
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
    >
      {value > 0 ? (
        <strong>+{getNumberFormat(value.toFixed(2))}</strong>
      ) : (
        <strong>-{getNumberFormat((value * -1).toFixed(2))}</strong>
      )}
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
