import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import NumberFormat from 'react-number-format';

import config from '../config';

import __ from '../lang';

let ln = 'np';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
  rowX: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
  },
  lblLeft: {
    width: '120px',
    fontSize: '13px',
    fontWeight: 500,
  },
});

function PLForm({ data, lang }: any) {
  ln = lang;
  const classes = useStyles();

  const [requestObj, setRequestObj]: any = useState({
    share_count: 0,
    share_action: 'B',
    buy_price: 0,
    sell_price: 0,
    amount: 0,
    broker_commission: 0,
    sebon_fee: 0,
    demat_fee: 0,
    total_amount: 0,
    net_profit: 0,
    capital_gain_tax: 0,
    holding_period: 'S',
  });

  const [appData, setAppData] = useState({
    bonus_cash_tax: config.bonus_cash_tax,
    broker_commission: config.broker_commission,
    sebon_fee: config.sebon_fee,
    demat_fee: config.demat_fee,
  });

  useEffect(() => {
    if (data) {
      setAppData((preState: any) => ({
        ...preState,
        bonus_cash_tax: data.bonus_cash_tax,
        broker_commission: data.broker_commission,
        sebon_fee: data.sebon_fee,
        demat_fee: data.demat_fee,
      }));
    }
  }, [data]);

  const _calculate = () => {
    const {
      share_count,
      share_action,
      buy_price,
      sell_price,
      holding_period,
    } = requestObj;

    let broker_commission = 0;
    let sebon_fee = 0;
    let demat_fee = 0;
    let total_amount = 0;
    let net_profit = 0;
    let capital_gain_tax = 0;
    let amount = 0;
    let amount_2 = 0;
    let broker_commission_2 = 0;
    let sebon_fee_2 = 0;
    let demat_fee_2 = 0;

    switch (share_action) {
      case 'B':
        amount = share_count * buy_price;

        broker_commission = getBrokerCommission(amount, appData);
        sebon_fee = getSebonFee(amount, appData);
        demat_fee = getDematFee(amount, appData);

        if (amount > 0)
          total_amount = amount + broker_commission + sebon_fee + demat_fee;
        break;
      case 'S':
        amount_2 = share_count * buy_price;
        broker_commission_2 = getBrokerCommission(amount_2, appData);
        sebon_fee_2 = getSebonFee(amount_2, appData);
        demat_fee_2 = getDematFee(amount_2, appData);

        const payAmt_1 =
          amount_2 + broker_commission_2 + sebon_fee_2 + demat_fee_2;

        amount = share_count * sell_price;
        broker_commission = getBrokerCommission(amount, appData);
        sebon_fee = getSebonFee(amount, appData);
        demat_fee = getDematFee(amount, appData);

        const payAmt_2 = amount - broker_commission - sebon_fee - demat_fee;

        net_profit = payAmt_2 - payAmt_1;
        capital_gain_tax =
          net_profit > 0
            ? (net_profit * (holding_period === 'L' ? 5 : 7.5)) / 100
            : 0;

        if (amount > 0) {
          total_amount =
            amount -
            broker_commission -
            sebon_fee -
            demat_fee -
            capital_gain_tax;

          net_profit = total_amount - payAmt_1;
        }
        break;
    }

    setRequestObj((preState: any) => ({
      ...preState,
      amount,
      broker_commission,
      sebon_fee,
      demat_fee,
      total_amount,
      net_profit,
      capital_gain_tax,
    }));
  };

  const handleChange = (value: any, type: string) => {
    if (type != 'share_action' && type != 'holding_period' && !(value > 0)) {
      if (value == '') value = 0;
      else return;
    }

    requestObj[type] =
      type == 'share_action' || type == 'holding_period'
        ? value
        : parseFloat(value);
    setRequestObj(requestObj);
    _calculate();
  };

  const {
    total_amount,
    share_action,
    share_count,
    buy_price,
    sell_price,
    amount,
    broker_commission,
    sebon_fee,
    demat_fee,
    capital_gain_tax,
    net_profit,
    holding_period,
  } = requestObj;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {total_amount > 0 && (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          {share_action == 'B' &&
            (lang == 'np' ? (
              <div>
                यदि तपाईंले {getFormatQty(share_count)} किता सेयर{' '}
                {getFormatAmount(buy_price)} को दरले किन्नु भयो भने तपाईंले{' '}
                {getFormatAmountX(total_amount, share_action)} तिर्नु पर्ने
                हुन्छ!
              </div>
            ) : (
              <div>
                If you buy {getFormatQty(share_count)}
                {share_count == 1 ? ' share' : ' shares'} at the price of{' '}
                {getFormatAmount(buy_price)} then you will pay{' '}
                {getFormatAmountX(total_amount, share_action)}
              </div>
            ))}

          {share_action == 'S' &&
            (lang == 'np' ? (
              <div>
                यदि तपाईंले {getFormatQty(share_count)} किता सेयर{' '}
                {getFormatAmount(sell_price)} को दरले बेच्नु भयो भने तपाईंले{' '}
                {getValueColorX(total_amount)} पाउनु हुनेछ!
              </div>
            ) : (
              <div>
                If you sell {getFormatQty(share_count)}
                {share_count == 1 ? ' share' : ' shares'} at the price of{' '}
                {getFormatAmount(sell_price)} then you will get{' '}
                {getValueColorX(total_amount)}
              </div>
            ))}

          <div style={{ marginTop: '5px' }}>
            <Typography
              style={{ fontWeight: 600 }}
              className={classes.lblLeft}
              component="span"
            >
              {__('DETAILS', lang)}
            </Typography>
            <Grid container direction="row">
              <Grid style={{ fontSize: '12px' }} item xs={4}>
                <Typography className={classes.lblLeft} component="span">
                  {__('PRICE', lang)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <span>{getAmount(amount)}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={4}>
                <Typography className={classes.lblLeft} component="span">
                  {__('BROKER_COMMISSION', lang)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <span>{getFormatPayAmount(broker_commission)}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={4}>
                <Typography className={classes.lblLeft} component="span">
                  {__('SEBON_FEE', lang)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <span>{getFormatPayAmount(sebon_fee)}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={4}>
                <Typography className={classes.lblLeft} component="span">
                  {__('DEMAT_FEE', lang)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <span>{getFormatPayAmount(demat_fee)}</span>
              </Grid>
            </Grid>
            {share_action == 'S' && (
              <Grid container direction="row">
                <Grid item xs={4}>
                  <Typography className={classes.lblLeft} component="span">
                    {__('CAPITAL_GAIN_TAX', lang)} (
                    {holding_period === 'S' ? '7.5%' : '5%'})
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <span>{getFormatPayAmount(capital_gain_tax)}</span>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row">
              <Grid item xs={4}>
                {share_action == 'S' ? (
                  <Typography className={classes.lblLeft} component="span">
                    {__('TOTAL_RECEIVABLE', lang)}
                  </Typography>
                ) : (
                  <Typography className={classes.lblLeft} component="span">
                    {__('TOTAL_PAYABLE', lang)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <span>{getValueColorByAction(total_amount, share_action)}</span>
              </Grid>
            </Grid>
            {share_action == 'S' && (
              <Grid container direction="row">
                <Grid item xs={4}>
                  <Typography className={classes.lblLeft} component="span">
                    {net_profit > 0 ? __('PROFIT', lang) : __('LOSS', lang)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <span> {getValueColor(net_profit)}</span>
                </Grid>
              </Grid>
            )}
          </div>
        </Paper>
      )}

      {/* <Typography variant="subtitle1" color="textPrimary">
        व्यक्तिगत शेर खरिद बिक्री क्यालकुलेटर
      </Typography> */}
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {__('WHAT_BUSINESS_DOING', lang)}
          </FormLabel>
          <RadioGroup
            value={share_action}
            onChange={(e: any) => handleChange(e.target.value, 'share_action')}
          >
            <div style={{ flexDirection: 'row' }}>
              <FormControlLabel
                value="B"
                control={<Radio />}
                label={__('BUY_SHARE', lang)}
              />
              <FormControlLabel
                value="S"
                control={<Radio />}
                label={__('SELL_SHARE', lang)}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <TextField
          id="share-no"
          label={
            share_action == 'S'
              ? __('WHAT_SHARE_QTY_SELL', lang)
              : __('WHAT_SHARE_QTY_BUY', lang)
          }
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'share_count')}
          autoComplete="off"
          value={share_count == 0 ? '' : share_count}
          style={{ width: '300px' }}
        />
      </div>
      {share_action == 'S' && share_count > 0 && (
        <div style={{ marginTop: '10px' }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {__('HOLDING_PERIOD', lang)}
            </FormLabel>
            <RadioGroup
              value={holding_period}
              onChange={(e: any) =>
                handleChange(e.target.value, 'holding_period')
              }
            >
              <div style={{ flexDirection: 'row' }}>
                <FormControlLabel
                  value="S"
                  control={<Radio />}
                  label={__('HOLDING_SHORT', lang)}
                />
                <FormControlLabel
                  value="L"
                  control={<Radio />}
                  label={__('HOLDING_LONG', lang)}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <div>
        <TextField
          label={__('BUY_PRICE', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'buy_price')}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
      {share_action == 'S' && (
        <div>
          <div>
            <TextField
              label={__('SELL_PRICE', lang)}
              variant="outlined"
              size="small"
              placeholder={__('ENTER_TEXT', lang)}
              onChange={(e: any) => handleChange(e.target.value, 'sell_price')}
              autoComplete="off"
            />
          </div>
        </div>
      )}
    </form>
  );
}

export default PLForm;

const getMaxValue = (value: number) => {
  const values = [];
  values.push(value);
  values.push(10);

  return Math.max(...values);
};

const getBrokerCommission = (value: number, appData: any) => {
  const broker_commissions = appData.broker_commission;

  let bcValue = 0;

  broker_commissions.forEach((v: any, i: number) => {
    if (value >= v.low && value <= v.high) {
      bcValue = getMaxValue((value * v.value) / 100);
    }
  });

  if (bcValue == 0) {
    const lastElement = broker_commissions[broker_commissions.length - 1];

    bcValue = getMaxValue((value * lastElement.value) / 100);
  }

  return bcValue;
};
const getSebonFee = (value: number, appData: any) => {
  return (value * config.sebon_fee) / 100;
};
const getDematFee = (value: number, appData: any) => {
  return appData.demat_fee;
};

const getFormatAmount = (value: any) => {
  return (
    <Typography
      color={value > 0 ? 'primary' : 'secondary'}
      component="span"
      style={{ fontSize: '22px' }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getFormatQty = (value: any) => {
  return (
    <Typography
      color={value > 0 ? 'primary' : 'secondary'}
      component="span"
      style={{ fontSize: '22px' }}
    >
      <strong>{getNumberFormatWPrefix(value)}</strong>
    </Typography>
  );
};

const getFormatPayAmount = (value: any) => {
  return (
    <Typography color={'secondary'} component="span">
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getFormatAmountX = (value: any, share_action: string) => {
  return (
    <Typography
      color={share_action == 'S' ? 'primary' : 'secondary'}
      component="span"
      style={{ fontSize: '22px' }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getFormatValue = (value: any) => {
  return (
    <Typography color="primary" component="span" style={{ fontSize: '22px' }}>
      <strong>{getNumberFormat(value)}</strong>
    </Typography>
  );
};

const getAmount = (value: any) => {
  return (
    <Typography color={value >= 0 ? 'primary' : 'secondary'} component="span">
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getValueColorByAction = (value: any, share_action: any) => {
  return (
    <Typography
      style={{
        color: share_action == 'S' ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
      component="span"
    >
      {value > 0 ? (
        <strong>{getNumberFormat(value.toFixed(2))}</strong>
      ) : (
        <strong>-{getNumberFormat((value * -1).toFixed(2))}</strong>
      )}
    </Typography>
  );
};

const getValueColor = (value: any) => {
  return (
    <Typography
      style={{
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
      component="span"
    >
      {value > 0 ? (
        <strong>{getNumberFormat(value.toFixed(2))}</strong>
      ) : (
        <strong>-{getNumberFormat((value * -1).toFixed(2))}</strong>
      )}
    </Typography>
  );
};
const getValueColorX = (value: any) => {
  return (
    <Typography
      style={{
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
        fontSize: '22px',
      }}
      component="span"
    >
      {value > 0 ? (
        <strong>{getNumberFormat(value.toFixed(2))}</strong>
      ) : (
        <strong>-{getNumberFormat((value * -1).toFixed(2))}</strong>
      )}
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={ln === 'np' ? 'रु' : 'रु'}
    />
  );
};

const getNumberFormatWPrefix = (value: any) => {
  return (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
  );
};
