import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Link from '@mui/material/Link';

import BonusForm from './form/BonusForm';
import PLForm from './form/PLForm';
import BonusPriceAdjustment from './form/BonusPriceAdjustment';
import RightSharePriceAdjustment from './form/RightSharePriceAdjustment';
import GForm from './form/GForm';
import EMIForm from './form/EMI';
import IncomeForm from './form/IncomeTax';
import MarginLending from './form/MarginLending';
import CompanyDetail from './form/CompanyDetail';
import StockList from './form/StockList';

import LangSwiter from './LangSwitcher';
import { fetchApi } from './Network';

import __ from './lang';
import config from './config';

const options = [
  { name: 'INDIVIDUAL_SHARE_CALCULATOR', value: 1 },
  { name: 'EQUITY_BONUS_SHARE_CALCULATOR', value: 2 },
  { name: 'BONUS_SHARE_PRICE_ADJUSTMENT_CALCULATOR', value: 3 },
  { name: 'RIGHT_SHARE_PRICE_ADJUSTMENT_CALCULATOR', value: 4 },
  { name: 'GRAHAM_CALCULATOR', value: 5 },
  { name: 'EMI_CALCULATOR', value: 6 },
  { name: 'INCOME_TAX', value: 7 },
  { name: 'MARGIN_LENDING', value: 8 },
  { name: 'COMPANY_DETAILS', value: 9 },
  { name: 'STOCK_LIST', value: 10 },
];

const useStyles = makeStyles({
  root: {
    marginLeft: '8px',
  },
});

function App() {
  const classes = useStyles();
  const [option, setOption] = useState(0);
  const [np, setNp] = useState(false);
  const [data, setData] = useState(null);
  const [visibleOptions, setVisibleOption] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

  useEffect(() => {
    fetchApi(config.api_url, { ref: 'web' }, (resp: any) => {
      if (resp && !resp.error) {
        setData(resp);
        if (resp.visible_options) {
          const a = resp.visible_options;
          setVisibleOption(a);
        }
      }
    });
  }, []);

  const handleChange = (event: any) => {
    setOption(parseInt(event.target.value));
  };

  const _onSwitchLang = (lang: boolean) => {
    setNp(lang);
  };

  const lang = np ? 'np' : 'en';

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginTop: '20px', padding: '10px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <LangSwiter onSwitchLang={_onSwitchLang} />
          </div>
          <FormControl className={classes.root}>
            <InputLabel
              style={{ marginTop: '0px', marginLeft: '-15px' }}
              htmlFor="share-calculator"
            >
              {__('SELECT_SHARE_CALCUALTOR', lang)}
            </InputLabel>
            <NativeSelect
              id="share-calculator"
              style={{ width: '300px', fontSize: '13px' }}
              value={option}
              onChange={handleChange}
            >
              <option aria-label="none" value="" />

              {options.map((o, i) =>
                visibleOptions.includes(o.value) ? (
                  <option key={i} value={o.value}>
                    {__(o.name, lang)}
                  </option>
                ) : null
              )}
            </NativeSelect>
          </FormControl>
        </div>
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
          }}
        >
          {option === 2 && visibleOptions.includes(2) && (
            <BonusForm data={data} lang={lang} />
          )}
          {option === 1 && visibleOptions.includes(1) && (
            <PLForm data={data} lang={lang} />
          )}
          {option === 3 && visibleOptions.includes(3) && (
            <BonusPriceAdjustment data={data} lang={lang} />
          )}
          {option === 4 && visibleOptions.includes(4) && (
            <RightSharePriceAdjustment data={data} lang={lang} />
          )}
          {option === 5 && visibleOptions.includes(5) && (
            <GForm data={data} lang={lang} />
          )}
          {option === 6 && visibleOptions.includes(6) && (
            <EMIForm data={data} lang={lang} />
          )}
          {option === 7 && visibleOptions.includes(7) && (
            <IncomeForm data={data} lang={lang} />
          )}
          {option === 8 && visibleOptions.includes(8) && (
            <MarginLending data={data} lang={lang} />
          )}
          {option === 9 && visibleOptions.includes(9) && (
            <CompanyDetail data={data} lang={lang} />
          )}
          {option === 10 && visibleOptions.includes(10) && (
            <StockList data={data} lang={lang} />
          )}
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          right: 1,
          bottom: 1,
          fontSize: '9px',
        }}
      >
        Developed by{' '}
        <Link
          rel="noopener"
          style={{ color: '#000000' }}
          title="Dinesh Maharjan - Full Stack Engineer"
          target="_blank"
          href="https://dineshmaharjan.com.np"
          underline="none"
        >
          @httpdeveloper
        </Link>
      </div>
    </div>
  );
}

export default App;
