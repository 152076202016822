/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import StockList from './stocklist.json';
import __ from './lang';
import config from './config';

export default function StockDropdown({ data, onAfterSelect, lang }: any) {
  const [stocks, setStocks] = useState(StockList);

  useEffect(() => {
    if (data) {
      setStocks(data.stocks);
    }
  }, [data]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onAfterSelect(newValue);
      }}
      id="combo-box-demo"
      options={stocks}
      getOptionLabel={(option) => `${option.name} (${option.symbol})`}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={__('SELECT_STOCK', lang)}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
}
