const np = {
  SELECT_SHARE_CALCUALTOR: 'सेयर क्यालकुलेटर चयन गर्नुहोस्',
  INDIVIDUAL_SHARE_CALCULATOR: 'व्यक्तिगत सेयर खरिद बिक्री',
  EQUITY_BONUS_SHARE_CALCULATOR: 'इक्विटी बोनस सेयर/क्यास',
  BONUS_SHARE_PRICE_ADJUSTMENT_CALCULATOR: 'बोनस सेयर मूल्य समायोजन',
  RIGHT_SHARE_PRICE_ADJUSTMENT_CALCULATOR: 'राईट सेयर मूल्य समायोजन',
  DETAILS: 'विवरण',
  PRICE: 'मुल्य',
  BROKER_COMMISSION: 'ब्रोकर कमीशन',
  SEBON_FEE: 'सेबन फीस',
  DEMAT_FEE: 'डीमैट शुल्क',
  CAPITAL_GAIN_TAX: 'पूंजी लाभ कर',
  TOTAL_RECEIVABLE: 'कुल प्राप्त',
  TOTAL_PAYABLE: 'कुल देय',
  PROFIT: 'नाफा',
  LOSS: 'घाटा',
  WHAT_BUSINESS_DOING: 'के कारोबर गर्दै हुनुहुन्छ',
  BUY_SHARE: 'सेयर खरिद',
  SELL_SHARE: 'सेयर बिक्री',
  WHAT_SHARE_QTY_SELL: 'कती किता सेयर बिक्री गर्दै हुनुहुन्छ?',
  WHAT_SHARE_QTY_BUY: 'कती किता सेयर खरिद गर्दै हुनुहुन्छ?',
  ENTER_TEXT: 'यहाँ राख्नु होश',
  BUY_PRICE: 'खरिद मुल्य',
  SELL_PRICE: 'बिक्री मुल्य',
  SHARE_COUNT: 'तपाईं सँग कती कित्ता सेयर छ',
  BONUS_SHARE: 'कम्पनीले कती प्रतिसत बोनस सेयर दिदै छ(%)',
  RIGHT_SHARE: 'कम्पनीले कती प्रतिसत राईट सेयर दिदै छ(%)',
  BONUS_CASH: 'कम्पनीले कती प्रतिसत बोनस क्यास दिदै छ(%)',
  SELECT_STOCK: 'स्टक चयन गर्नुहोस्',
  LTP_BEFORE_BCD: 'बुक क्लोज डेट अगाडिको बजार मुल्य (LTP)',
  GRAHAM_CALCULATOR: 'निष्पक्ष सेयर मूल्य',
  PRICE_TO_EARNING_RATIO: 'पि/ई अनुपात',
  PRICE_TO_BOOK_RATIO: 'Price to Book Ratio',
  EPS: 'ईपिएस',
  BPVS: 'बुक भ्यालु',
  MARKET_PRICE: 'बजार मूल्य',
  EMI_CALCULATOR: 'ईएमआई',
  PRINCIPAL_AMOUNT: 'ऋण रकम',
  INTEREST_RATE: 'ब्याज दर (%)',
  PERIOD: 'अवधि (साल)',
  TOTAL_INTEREST: 'कुल ब्याज',
  TOTAL_PAYMENT: 'कुल भुक्तानी',
  EMI: 'ईएमआई',
  INCOME_TAX: 'आयकर',
  MONTHLY_TAX: 'मासिक',
  YEARLY_TAX: 'वार्षिक',
  TAX_LIABILITY: 'कर दायित्व',
  MARITAL_STATUS: 'वैवाहिक स्थिति',
  INDIVIDUAL: 'एकल',
  MARRIED: 'विवाहित',
  MONTHLY_INCOME: 'महिनाको आम्दानी',
  MARGIN_LENDING: 'मार्जिन लेन्दिङ',
  MARGIN_AMOUNT: 'मार्जिन रकम',
  MARGIN_PERCENT: 'मार्जिन प्रतिशत',
  AVG_MKT_PRICE: '180 दिन बजार औसत मूल्य',
  MARGIN_SHARE_COUNT: 'सेयर युनितस',
  COMPANY_DETAILS: 'स्टॉक विवरण',
  NAME: 'नाम',
  SYMBOL: 'प्रतीक',
  BOOK_VALUE: 'बुक भ्यालु',
  OUTSTANDING_SHARES: 'सूचीबद्ध सेयर सन्ख्या',
  MARKET_CAPITALIZATION: 'बाजार पूंजीकरण',
  RSI: 'आरएसआई (14)',
  MA: 'औसत मूल्य (180 दिन)',
  LTP: 'एलटीपी',
  PE: 'पीई अनुपात',
  LAST_UPDATED: 'पछिल्लो अपडेट गरिएको',
  HOLDING_PERIOD: 'होल्डिंग अवधि',
  HOLDING_SHORT: 'छोटो समय (सीजीटी 7.5%)',
  HOLDING_LONG: 'लामो समय (सीजीटी 5%)',
  STOCK_LIST: 'स्टक सूची',
  TABLE_BODY_NOMATCH: 'माफ गर्नुहोस्, कुनै मिल्दो रेकर्ड फेला परेन',
  TABLE_BODY_TOOLTIP: 'क्रमबद्ध गर्नुहोस्',
  TABLE_BODY_SORT_FOR: 'को लागि क्रमबद्ध गर्नुहोस्',
  TABLE_PAGINATION_NEXT: 'अर्को पाना',
  TABLE_PAGINATION_PREV: 'अघिल्लो पृष्ठ',
  TABLE_PAGINATION_PER_PAGE: 'प्रति पृष्ठ पङ्क्तिहरू:',
  TABLE_PAGINATION_OF: 'को',
  TABLE_TOOLBAR_SEARCH: 'खोज्नुहोस्',
  TABLE_TOOLBAR_DOWNLOAD_CV: 'Csv डाउनलोड गर्नुहोस्',
  TABLE_TOOLBAR_PRINT: 'छाप्नुहोस्',
  TABLE_TOOLBAR_VIEW_COLUMNS: 'स्तम्भहरू हेर्नुहोस्',
  TABLE_TOOLBAR_FILTER_TABLE: 'फिल्टर तालिका',
  TABLE_FILTER_ALL: 'सबै',
  TABLE_FILTER_TITLE: 'फिल्टरहरू',
  TABLE_FILTER_RESET: 'रिसेट गर्नुहोस्',
  TABLE_VIEW_COLUMNS: 'स्तम्भहरू देखाउनुहोस्',
  TABLE_VIEW_SH_TITLE: 'तालिका स्तम्भहरू देखाउनुहोस्/लुकाउनुहोस्',
  TABLE_SELECTED_ROWS_TEXT: 'पङ्क्ति(हरू) चयन गरियो',
  TABLE_SELECTED_ROWS_DELETE: 'मेटाउन',
  TABLE_SELECTED_ROWS_DELETE_ARIA: 'चयन गरिएका पङ्क्तिहरू मेटाउनुहोस्',
  PBV: 'पीबीभि',
  LOW_52: '५२ हप्ता न्यून',
  HIGH_52: '५२ हप्ता उच्च',
  SECTOR_NAME: 'क्षेत्र',
  VOLUME: 'भोल्युम',
};

export default np;
