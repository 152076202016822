import config from './config';

export const fetchApi = (endpoint: any, data: any, onAfterFetch: any) => {
  const formData = new FormData();
  for (let i in data) {
    if (data[i]) {
      formData.append(i, data[i]);
    }
  }
  fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${config.token}`,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then(
      (result) => {
        onAfterFetch(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        onAfterFetch({ error: true });
      }
    );
};
