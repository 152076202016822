import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import NumberFormat from 'react-number-format';

import Stocks from '../stocks';
import config from '../config';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

export default function MarginLendingForm({ data, lang }: any) {
  const classes = useStyles();

  const [request, setRequest]: any = useState({
    stock_name: '',
    share_count: 0,
    margin_percent: 0,
    margin_amount: 0,
    ma180_price: -1,
    stocks: [],
  });

  useEffect(() => {
    if (data) {
      setRequest((preRequest: any) => ({
        ...preRequest,
        stocks: data['stocks'],
      }));
    }
  }, [data]);

  const _getMarginAmount = (ma180_price: any) => {
    const { share_count, margin_percent } = request;

    let margin_amount = share_count * ma180_price;

    return (margin_amount * margin_percent) / 100;
  };

  const _calculate = () => {
    const { share_count, margin_percent, ma180_price } = request;

    let margin_amount = share_count * ma180_price;

    margin_amount = (margin_amount * margin_percent) / 100;

    setRequest((preRequest: any) => ({
      ...preRequest,
      margin_amount,
    }));
  };

  const handleChange = (value: any, type: string) => {
    if (value.trim() == '') value = 0;

    if (value >= 0) {
      request[type] = parseFloat(value);
      setRequest(request);
      _calculate();
    }
  };

  const _onAfterSelect = (data: any) => {
    let stockSymbol = '';
    if (data && data != null) {
      stockSymbol = data.symbol;
    }

    if (stockSymbol) {
      const { stocks } = request;
      const selectedStock = stocks.find((s: any) => s.symbol == stockSymbol);

      if (selectedStock) {
        const avgPrice = parseFloat(selectedStock.ma_180);

        return setRequest((preRequest: any) => ({
          ...preRequest,
          ma180_price: avgPrice,
          margin_amount: _getMarginAmount(avgPrice),
        }));
      }
    }

    setRequest((preRequest: any) => ({
      ...preRequest,
      ma180_price: -1,
      margin_amount: 0,
    }));
  };

  const { share_count, ma180_price } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {request.margin_amount > 0 ? (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          <Typography variant="subtitle1">
            {__('MARGIN_AMOUNT', lang)}: {getValue(request.margin_amount)}
          </Typography>
        </Paper>
      ) : (
        <div />
      )}

      <div>
        <Stocks data={data} lang={lang} onAfterSelect={_onAfterSelect} />
      </div>
      {ma180_price >= 0 && (
        <Typography style={{ fontSize: '11px', width: '260px' }}>
          {__('AVG_MKT_PRICE', lang)}: {getSLabel(ma180_price)}
        </Typography>
      )}
      <div>
        <TextField
          id="share-no"
          label={__('MARGIN_SHARE_COUNT', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'share_count')}
          value={share_count == 0 ? '' : share_count}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          id="bonus-dividend"
          label={__('MARGIN_PERCENT', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) => handleChange(e.target.value, 'margin_percent')}
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
    </form>
  );
}

const getValue = (value: any) => {
  return (
    <Typography
      component="span"
      style={{
        fontSize: '22px',
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getSLabel = (value: any) => {
  return (
    <Typography
      component="span"
      color="primary"
      style={{
        fontSize: '12px',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
