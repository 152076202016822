import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Stocks from '../stocks';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
  table: {
    minWidth: 300,
  },
});

export default function CompanyDetail({ data, lang }: any) {
  const classes = useStyles();

  const [request, setRequest]: any = useState({
    stock_name: '',
    stock: null,
    stocks: [],
  });

  useEffect(() => {
    if (data) {
      setRequest((preRequest: any) => ({
        ...preRequest,
        stocks: data['stocks'],
      }));
    }
  }, [data]);

  const _onAfterSelect = (data: any) => {
    let stockSymbol = '';
    if (data && data != null) {
      stockSymbol = data.symbol;
    }

    if (stockSymbol) {
      const { stocks } = request;
      const selectedStock = stocks.find((s: any) => s.symbol == stockSymbol);

      if (selectedStock) {
        return setRequest((preRequest: any) => ({
          ...preRequest,
          stock: selectedStock,
        }));
      }
    }

    setRequest((preRequest: any) => ({
      ...preRequest,
      stock: null,
    }));
  };

  const { stock } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {stock && (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>{__('NAME', lang)}</TableCell>
                <TableCell scope="row">{stock.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('SYMBOL', lang)}</TableCell>
                <TableCell scope="row">{stock.symbol}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('LTP', lang)}</TableCell>
                <TableCell scope="row">{getNumberFormat(stock.ltp)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('EPS', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.eps, '')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('PE', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.pe, '')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('BOOK_VALUE', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.book_value)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('OUTSTANDING_SHARES', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.outstanding_shares, '')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('MARKET_CAPITALIZATION', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.market_capitalization)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('RSI', lang)}</TableCell>
                <TableCell scope="row">{stock.rsi}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{__('MA', lang)}</TableCell>
                <TableCell scope="row">
                  {getNumberFormat(stock.ma_180)}
                </TableCell>
              </TableRow>

              {stock['extra'] &&
                stock['extra'].length > 0 &&
                stock['extra'].map((r: any, i: number) => {
                  return (
                    <TableRow>
                      <TableCell>{r.label}</TableCell>
                      <TableCell scope="row">{r.value}</TableCell>
                    </TableRow>
                  );
                })}

              <TableRow>
                <TableCell>{__('LAST_UPDATED', lang)}</TableCell>
                <TableCell scope="row">{stock.last_updated}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      )}

      <div>
        <Stocks data={data} lang={lang} onAfterSelect={_onAfterSelect} />
      </div>
    </form>
  );
}

const getValue = (value: any) => {
  return (
    <Typography
      component="span"
      style={{
        fontSize: '22px',
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getSLabel = (value: any) => {
  return (
    <Typography
      component="span"
      color="primary"
      style={{
        fontSize: '12px',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getNumberFormat = (value: any, prefix = 'रु') => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={prefix}
    />
  );
};
