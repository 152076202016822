import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

function BonusPriceAdjustment({ data, lang }: any) {
  const [request, setRequest]: any = useState({
    bonus: 0,
    ltp: 0,
    market_price: 0,
  });

  const classes = useStyles();

  const _calculate = () => {
    const { bonus, ltp } = request;

    const market_price = ltp / (1 + bonus / 100);

    setRequest((preState: any) => ({
      ...preState,
      market_price,
    }));
  };

  const handleChange = (value: any, type: any) => {
    request[type] = parseFloat(value);
    setRequest(request);
    _calculate();
  };

  const { bonus, ltp, market_price } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {market_price > 0 && ltp > 0 && bonus > 0 && (
        <Paper style={{ padding: '10px' }}>
          {lang == 'np' ? (
            <div>
              बुक क्लोज डेट पछाडिको बजार मूल्य: {formatValue(market_price)}
            </div>
          ) : (
            <div>
              Market Price After Price Adjustment: {formatValue(market_price)}
            </div>
          )}
        </Paper>
      )}
      <div>
        <TextField
          autoFocus={false}
          id="share-no"
          label={__('LTP_BEFORE_BCD', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'ltp')}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          autoFocus={false}
          id="cash-dividend"
          label={__('BONUS_SHARE', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) => handleChange(e.target.value, 'bonus')}
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
    </form>
  );
}

export default BonusPriceAdjustment;

const formatValue = (value: any) => {
  return (
    <Typography
      component="span"
      color="primary"
      style={{
        fontSize: '22px',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
