const en = {
  SELECT_SHARE_CALCUALTOR: 'Select Share Calculator',
  INDIVIDUAL_SHARE_CALCULATOR: 'Buy / Sell Share',
  EQUITY_BONUS_SHARE_CALCULATOR: 'Bonus Share / Cash Dividend',
  BONUS_SHARE_PRICE_ADJUSTMENT_CALCULATOR: 'Bonus Share Price Adjustment',
  RIGHT_SHARE_PRICE_ADJUSTMENT_CALCULATOR: 'Right Share Price Adjustment',
  DETAILS: 'Details',
  PRICE: 'Amount',
  BROKER_COMMISSION: 'Broker Commission',
  SEBON_FEE: 'Sebon Fee',
  DEMAT_FEE: 'Demat Fee',
  CAPITAL_GAIN_TAX: 'Capital Gain Tax',
  TOTAL_RECEIVABLE: 'Total Receivable',
  TOTAL_PAYABLE: 'Total Payable',
  PROFIT: 'Profit',
  LOSS: 'Loss',
  WHAT_BUSINESS_DOING: 'Transaction Type',
  BUY_SHARE: 'Buy Share',
  SELL_SHARE: 'Sell Share',
  WHAT_SHARE_QTY_SELL: 'Sell Quantity',
  WHAT_SHARE_QTY_BUY: 'Buy Quantity',
  ENTER_TEXT: 'Type Here..',
  BUY_PRICE: 'Buy Price',
  SELL_PRICE: 'Sell Price',
  SHARE_COUNT: 'Share Quantity',
  BONUS_SHARE: '(%) Bonus Share',
  RIGHT_SHARE: '(%) Right Share',
  BONUS_CASH: '(%) Cash Dividend',
  SELECT_STOCK: 'Select Stock',
  LTP_BEFORE_BCD: 'LTP Before Book Close Date',
  GRAHAM_CALCULATOR: 'Stock Fair Value by Graham Number',
  PRICE_TO_EARNING_RATIO: 'P/E (Price to Earning) Ratio',
  PRICE_TO_BOOK_RATIO: 'Price to Book Ratio',
  EPS: 'EPS (Earning Per Share)',
  BPVS: 'Book Value Per Share',
  MARKET_PRICE: 'Market Price',
  EMI_CALCULATOR: 'EMI',
  PRINCIPAL_AMOUNT: 'Loan Amount',
  INTEREST_RATE: 'Interest Rate (%)',
  PERIOD: 'Period (Years)',
  TOTAL_INTEREST: 'Total Interest',
  TOTAL_PAYMENT: 'Total Payment',
  EMI: 'EMI',
  INCOME_TAX: 'Income Tax',
  MONTHLY_TAX: 'Monthly',
  YEARLY_TAX: 'Yearly',
  TAX_LIABILITY: 'Tax Liability',
  MARITAL_STATUS: 'Marital Status',
  INDIVIDUAL: 'Single',
  MARRIED: 'Married',
  MONTHLY_INCOME: 'Monthly Income',
  MARGIN_LENDING: 'Margin Lending',
  MARGIN_AMOUNT: 'Margin Amount',
  MARGIN_PERCENT: 'Margin Percent',
  AVG_MKT_PRICE: '180 Days Average Market Price',
  MARGIN_SHARE_COUNT: 'Share Units',
  COMPANY_DETAILS: 'Stock Details',
  NAME: 'Name',
  SYMBOL: 'Symbol',
  BOOK_VALUE: 'Book value',
  OUTSTANDING_SHARES: 'Outstanding Shares',
  MARKET_CAPITALIZATION: 'Market Capitalization',
  RSI: 'RSI(14)',
  MA: 'Average Price (180 days)',
  LAST_UPDATED: 'Last Updated',
  HOLDING_PERIOD: 'Holding Period',
  HOLDING_SHORT: 'Short Term (CGT: 7.5%)',
  HOLDING_LONG: 'Long Term (CGT: 5%)',
  STOCK_LIST: 'Stock List',
  TABLE_BODY_NOMATCH: 'Sorry, no matching records found',
  TABLE_BODY_TOOLTIP: 'Sort',
  TABLE_BODY_SORT_FOR: 'Sort for',
  TABLE_PAGINATION_NEXT: 'Next Page',
  TABLE_PAGINATION_PREV: 'Previous Page',
  TABLE_PAGINATION_PER_PAGE: 'Rows per page:',
  TABLE_PAGINATION_OF: 'of',
  TABLE_TOOLBAR_SEARCH: 'Search',
  TABLE_TOOLBAR_DOWNLOAD_CV: 'downloadCsv',
  TABLE_TOOLBAR_PRINT: 'Print',
  TABLE_TOOLBAR_VIEW_COLUMNS: 'View Columns',
  TABLE_TOOLBAR_FILTER_TABLE: 'Filter Table',
  TABLE_FILTER_ALL: 'All',
  TABLE_FILTER_TITLE: 'FILTERS',
  TABLE_FILTER_RESET: 'RESET',
  TABLE_VIEW_COLUMNS: 'Show Columns',
  TABLE_VIEW_SH_TITLE: 'Show/Hide Table Columns',
  TABLE_SELECTED_ROWS_TEXT: 'row(s) selected',
  TABLE_SELECTED_ROWS_DELETE: 'Delete',
  TABLE_SELECTED_ROWS_DELETE_ARIA: 'Delete Selected Rows',
  LTP: 'LTP',
  PBV: 'PBV',
  PE: 'PE',
  LOW_52: '52 Weeks Low',
  HIGH_52: '52 Weeks High',
  SECTOR_NAME: 'Sector',
};

export default en;
