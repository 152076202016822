import ENL from './en';
import NPL from './np';

const __ = (name: string, type: any = '') => {
  const _ENL: any = ENL;
  const _NPL: any = NPL;

  switch (type) {
    case 'en':
      return _ENL[name] ? _ENL[name] : name;

    case 'np':
      return _NPL[name] ? _NPL[name] : name;
  }

  return name;
};

export default __;
