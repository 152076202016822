import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import NumberFormat from 'react-number-format';

import config from '../config';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

export default function EMI({ data, lang }: any) {
  const classes = useStyles();

  const [request, setRequest]: any = useState({
    principal: 0,
    interest: 0,
    period: 0,
    monthly_payment: 0,
    total_payment: 0,
    total_interest: 0,
  });

  const handleChange = (value: any, type: string) => {
    if (value.trim() == '') value = 0;

    if (value >= 0) {
      request[type] = parseFloat(value);
      setRequest(request);
      _calculate();
    }
  };

  const _calculate = () => {
    const principal = parseFloat(request.principal);
    const interest = parseFloat(request.interest) / 100 / 12;
    const periods = parseFloat(request.period) * 12;

    if (!(principal > 0 && interest > 0 && periods > 0))
      return setRequest((preRequest: any) => ({
        ...preRequest,
        monthly_payment: 0,
        total_payment: 0,
        total_interest: 0,
      }));

    // compute the monthly payment figure
    const x = Math.pow(1 + interest, periods);
    const monthly_payment = (principal * x * interest) / (x - 1);
    const total_payment = monthly_payment * periods;
    const total_interest = monthly_payment * periods - principal;
    setRequest((preRequest: any) => ({
      ...preRequest,
      monthly_payment,
      total_payment,
      total_interest,
    }));
  };

  const { monthly_payment, total_payment, total_interest } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {request.monthly_payment > 0 ? (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          <div>
            {__('EMI', lang)}: {getValue(monthly_payment)}
          </div>
          <div>
            {__('TOTAL_INTEREST', lang)}: {getValue(total_interest)}
          </div>
          <div>
            {__('TOTAL_PAYMENT', lang)}: {getValue(total_payment)}
          </div>
        </Paper>
      ) : (
        <div />
      )}

      <div>
        <TextField
          id="share-no"
          label={__('PRINCIPAL_AMOUNT', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'principal')}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          id="bonus-dividend"
          label={__('INTEREST_RATE', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) => handleChange(e.target.value, 'interest')}
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          id="cash-dividend"
          label={__('PERIOD', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) => handleChange(e.target.value, 'period')}
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
    </form>
  );
}

const getValue = (value: any) => {
  return (
    <Typography
      component="span"
      color="secondary"
      style={{
        fontSize: '22px',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
