import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import NumberFormat from 'react-number-format';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import __ from '../lang';
import config from '../config';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

export default function IncomeTax({ data, lang }: any) {
  const classes = useStyles();

  const [request, setRequest]: any = useState({
    monthly_income: 0,
    marital_status: 'I',
    monthly_tax: 0,
    tax_rates: config.tax_rates,
  });

  useEffect(() => {
    if (data && data.tax_rates) {
      setRequest((preState: any) => ({
        ...preState,
        tax_rates: data.tax_rates,
      }));
    }
  }, [data]);

  const handleChange = (value: any, type: string) => {
    if (value.trim() == '') value = 0;

    if (value >= 0 || type === 'marital_status') {
      request[type] = type === 'marital_status' ? value : parseFloat(value);
      setRequest(request);
      _calculate();
    }
  };

  const _calculate = () => {
    const { monthly_income, marital_status, tax_rates } = request;

    const yearly_income = monthly_income * 12;

    const taxes = tax_rates[marital_status];
    let yearly_tax = 0;

    const firstItem = taxes[0];

    if (firstItem.value >= yearly_income) {
      yearly_tax = (yearly_income * firstItem.tax) / 100;
      return setRequest((preRequest: any) => ({
        ...preRequest,
        monthly_tax: yearly_tax / 12,
      }));
    }

    let y = yearly_income;

    const n = taxes.length - 1;

    let addTax = true;

    taxes.forEach((item: any, i: number) => {
      if (addTax) {
        let tax = 0;

        if (y > item.value && i !== n) {
          tax = (item.value * item.tax) / 100;
        } else {
          tax = (y * item.tax) / 100;
        }

        if (y > item.value) {
          y = y - item.value;
        } else {
          addTax = false;
        }

        yearly_tax += tax;
      }
    });

    setRequest((preRequest: any) => ({
      ...preRequest,
      monthly_tax: yearly_tax / 12,
    }));
  };

  const { monthly_tax, marital_status } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {monthly_tax > 0 ? (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          <div>{__('TAX_LIABILITY', lang)}</div>
          <div>
            {__('MONTHLY_TAX', lang)}: {getValue(monthly_tax)}
          </div>
          <div>
            {__('YEARLY_TAX', lang)}: {getValue(monthly_tax * 12)}
          </div>
        </Paper>
      ) : (
        <div />
      )}

      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">{__('MARITAL_STATUS', lang)}</FormLabel>
          <RadioGroup
            value={marital_status}
            onChange={(e: any) =>
              handleChange(e.target.value, 'marital_status')
            }
          >
            <FormControlLabel
              value="I"
              control={<Radio />}
              label={__('INDIVIDUAL', lang)}
            />
            <FormControlLabel
              value="M"
              control={<Radio />}
              label={__('MARRIED', lang)}
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <TextField
          id="monthly_income"
          label={__('MONTHLY_INCOME', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'monthly_income')}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
    </form>
  );
}

const getValue = (value: any) => {
  return (
    <Typography
      component="span"
      color="secondary"
      style={{
        fontSize: '22px',
      }}
    >
      <strong>{getNumberFormat(value.toFixed(2))}</strong>
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
