const config = {
  api_url: 'https://sharecalculator.onlineadsbazar.com/config.php',
  bonus_cash_tax: 5,
  broker_commission: [
    { low: 0, high: 50000, value: 0.4 },
    { low: 50001, high: 600000, value: 0.37 },
    { low: 600001, high: 2000000, value: 0.34 },
    { low: 2000001, high: 10000000, value: 0.3 },
    { low: 10000001, high: 0, value: 0.27 },
  ],
  sebon_fee: 0.015,
  demat_fee: 25,
  price_to_book: 3,
  token: 'bmVwYWxzaGFyZW1hcmtldHN0b2NrY2FsY3VsYXRvcmJ5aHR0cGRldmVsb3Blcg==',
  tax_rates: {
    I: [
      { tax: 1, value: 400000 },
      { tax: 10, value: 100000 },
      { tax: 20, value: 200000 },
      { tax: 30, value: 1300000 },
      { tax: 36, value: 2000000 },
    ],
    M: [
      { tax: 1, value: 450000 },
      { tax: 10, value: 100000 },
      { tax: 20, value: 200000 },
      { tax: 30, value: 1250000 },
      { tax: 36, value: 2000000 },
    ],
  },
};

export default config;
