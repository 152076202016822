import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SwitchesSize({ onSwitchLang }: any) {
  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
    onSwitchLang(!checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            size="small"
            checked={checked}
            onChange={toggleChecked}
          />
        }
        label="नेपाली"
      />
    </FormGroup>
  );
}
