import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import NumberFormat from 'react-number-format';

import __ from '../lang';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '800px',
    margin: '0 auto',
  },
}));

const getColumns = (lang: any, extraCols: any) => {
  const c = [
    {
      name: 'symbol_name',
      label: __('SYMBOL', lang),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'ltp',
      label: __('LTP', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'book_value',
      label: __('BOOK_VALUE', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'eps',
      label: __('EPS', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'pbv',
      label: __('PBV', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'rsi',
      label: __('RSI', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'pe',
      label: __('PE', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'outstanding_shares',
      label: __('OUTSTANDING_SHARES', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'market_capitalization',
      label: __('MARKET_CAPITALIZATION', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'ma_180',
      label: __('MA', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'low_52',
      label: __('LOW_52', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'high_52',
      label: __('HIGH_52', lang),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          );
        },
      },
    },
    {
      name: 'sector_name',
      label: __('SECTOR_NAME', lang),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (extraCols && extraCols.length > 0) {
    extraCols.map((extraCol: any) => {
      c.push({
        name: extraCol.name,
        label: __(extraCol.label, lang),
        options: {
          filter: extraCol.filter,
          sort: extraCol.sort,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={''}
              />
            );
          },
        },
      });
    });
  }

  return c;
};

export default function StockList({ data, lang }: any) {
  const classes = useStyles();

  const [stocks, setStocks]: any = useState([]);
  const [extraCols, setExtraCols]: any = useState([]);
  const [columns, setColumns]: any = useState(getColumns(lang, extraCols));

  useEffect(() => {
    setStocks(data.stocks);
    setExtraCols(data.extraCols);
  }, [data]);

  useEffect(() => {
    setColumns(getColumns(lang, extraCols));
  }, [lang, extraCols]);

  const options: any = {
    rowsPerPageOptions: [10, 50, 100],
    download: false,
    print: false,
    responsive: 'standard',
    filter: true,
    filterType: 'checkbox',
    selectableRows: 'none',
    draggableColumns: {
      enabled: true,
    },
    textLabels: {
      body: {
        noMatch: __('TABLE_BODY_NOMATCH', lang),
        toolTip: __('TABLE_BODY_TOOLTIP', lang),
        columnHeaderTooltip: (column: any) =>
          `${__('TABLE_BODY_SORT_FOR', lang)} ${column.label}`,
      },
      pagination: {
        next: __('TABLE_PAGINATION_NEXT', lang),
        previous: __('TABLE_PAGINATION_PREV', lang),
        rowsPerPage: __('TABLE_PAGINATION_PER_PAGE', lang),
        displayRows: __('TABLE_PAGINATION_OF', lang),
      },
      toolbar: {
        search: __('TABLE_TOOLBAR_SEARCH', lang),
        downloadCsv: __('TABLE_TOOLBAR_DOWNLOAD_CV', lang),
        print: __('TABLE_TOOLBAR_PRINT', lang),
        viewColumns: __('TABLE_TOOLBAR_VIEW_COLUMNS', lang),
        filterTable: __('TABLE_TOOLBAR_FILTER_TABLE', lang),
      },
      filter: {
        all: __('TABLE_FILTER_ALL', lang),
        title: __('TABLE_FILTER_TITLE', lang),
        reset: __('TABLE_FILTER_RESET', lang),
      },
      viewColumns: {
        title: __('TABLE_VIEW_COLUMNS', lang),
        titleAria: __('TABLE_VIEW_SH_TITLE', lang),
      },
      selectedRows: {
        text: __('TABLE_SELECTED_ROWS_TEXT', lang),
        delete: __('TABLE_SELECTED_ROWS_DELETE', lang),
        deleteAria: __('TABLE_SELECTED_ROWS_DELETE_ARIA', lang),
      },
    },
  };

  if (!stocks.length) return <div />;

  function getMuiTheme() {
    return createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '12px',
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            dragCursor: {
              fontSize: '11px',
            },
          },
        },
      },
    });
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={
            lang == 'en'
              ? `NEPSE Stocks [Last Updated: ${stocks[0]['last_updated']}]`
              : `नेप्से स्टकहरू [पछिल्लो अपडेट: ${stocks[0]['last_updated']}]`
          }
          data={stocks}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </div>
  );
}
