import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NumberFormat from 'react-number-format';

import Stocks from '../stocks';
import config from '../config';

import __ from '../lang';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '8px',
      width: '300px',
    },
  },
});

export default function BonusCashForm({ data, lang }: any) {
  const classes = useStyles();

  const [request, setRequest]: any = useState({
    stock_name: '',
    share_count: 0,
    bonus_dividend_per: 0,
    cash_dividend_per: 0,
    total_dividend: 0,
    tax: 0,
    bonus_cash: 0,
    bonus_share: 0,
  });

  const [bonusTax, setBonusTax] = useState(config.bonus_cash_tax);

  useEffect(() => {
    if (data) {
      setBonusTax(data.bonus_cash_tax);
    }
  }, [data]);

  const handleChange = (value: any, type: string) => {
    if (value.trim() == '') value = 0;

    if (value >= 0) {
      request[type] = parseFloat(value);
      setRequest(request);
      _calculateBonusShare();
    }
  };

  const _onAfterSelect = (data: any) => {
    let stockName = '';
    if (data && data != null) {
      stockName = data.name;
    }

    setRequest((preState: any) => ({
      ...preState,
      stock_name: stockName,
    }));
  };

  const _calculateBonusShare = () => {
    const { share_count, bonus_dividend_per, cash_dividend_per } = request;
    const total_dividend_per = bonus_dividend_per + cash_dividend_per;

    let tax: any = 0;

    if (cash_dividend_per >= 0) {
      tax = (total_dividend_per * share_count * bonusTax) / 100;
    }

    const bonus_share = (bonus_dividend_per / 100) * share_count;
    const bonus_cash = share_count * cash_dividend_per - tax;

    setRequest((preRequest: any) => ({
      ...preRequest,
      bonus_cash,
      bonus_share,
    }));
  };

  const { share_count } = request;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {request.stock_name != '' &&
      request.share_count > 0 &&
      (request.bonus_share > 0 || request.bonus_cash > 0) ? (
        <Paper variant="elevation" style={{ padding: '10px' }}>
          {lang == 'np' ? (
            <div>
              तपाईंले {companyName(request.stock_name)} बाट{' '}
              {bonusShare(request.bonus_share.toFixed(2))} बोनस शेर प्राप्त
              हुनेछ र {bonusCashValue(request.bonus_cash.toFixed(2))}
              {request.bonus_cash > 0
                ? ` बोनस क्यास पाउनेछ!`
                : ` बोनश सेयर कर तिर्नु पर्नेछ!`}
            </div>
          ) : request.bonus_cash > 0 ? (
            <div>
              You will get {bonusShare(request.bonus_share.toFixed(2))} bonus
              share and {bonusCashValue(request.bonus_cash.toFixed(2))} cash
              dividend from {companyName(request.stock_name)}
            </div>
          ) : (
            <div>
              You will get {bonusShare(request.bonus_share.toFixed(2))} bonus
              share and you have to pay{' '}
              {bonusCashValue(request.bonus_cash.toFixed(2))} as bonus tax to{' '}
              {companyName(request.stock_name)}
            </div>
          )}
        </Paper>
      ) : (
        <div />
      )}

      <div>
        <Stocks data={data} lang={lang} onAfterSelect={_onAfterSelect} />
      </div>
      <div>
        <TextField
          id="share-no"
          label={__('SHARE_COUNT', lang)}
          variant="outlined"
          size="small"
          placeholder={__('ENTER_TEXT', lang)}
          onChange={(e: any) => handleChange(e.target.value, 'share_count')}
          value={share_count == 0 ? '' : share_count}
          autoComplete="off"
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          id="bonus-dividend"
          label={__('BONUS_SHARE', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) =>
            handleChange(e.target.value, 'bonus_dividend_per')
          }
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
      <div>
        <TextField
          id="cash-dividend"
          label={__('BONUS_CASH', lang)}
          variant="outlined"
          size="small"
          onChange={(e: any) =>
            handleChange(e.target.value, 'cash_dividend_per')
          }
          autoComplete="off"
          placeholder={__('ENTER_TEXT', lang)}
          style={{ width: '300px' }}
        />
      </div>
    </form>
  );
}

const companyName = (name: any) => {
  return (
    <Typography color="primary" component="span" style={{ fontSize: '16px' }}>
      <strong>{name}</strong>
    </Typography>
  );
};

const bonusShare = (name: any) => {
  return (
    <Typography
      component="span"
      style={{ fontSize: '22px', color: 'rgb(76, 175, 80)' }}
    >
      <strong>{name}</strong>
    </Typography>
  );
};

const bonusCashValue = (value: any) => {
  return (
    <Typography
      component="span"
      style={{
        fontSize: '22px',
        color: value > 0 ? 'rgb(76, 175, 80)' : 'rgb(220, 0, 78)',
      }}
    >
      {value > 0 ? (
        <strong>{getNumberFormat(value)}</strong>
      ) : (
        <strong>-{getNumberFormat(value * -1)}</strong>
      )}
    </Typography>
  );
};

const getNumberFormat = (value: any) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'रु'}
    />
  );
};
